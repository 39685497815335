import { Popover } from '@mui/material';
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MarkAsPaidModal from './components/MarkAsPaidModal/MarkAsPaidModal';
import {
  Wrapper,
  Top,
  LoadID,
  LoadName,
  Actions,
  Action,
  More,
  DriverInfo,
  DriverExtra,
  LoadsFlexColumn,
  LoadsFlexColumnRight,
  LoadsDays,
  LoadsPayMent,
  LoadsAssigned,
  LoadsPhone,
  Status,
  CarInfo,
  Car,
  CarName,
  Type,
  Vin,
  Details,
  DetailBlock,
  DetailTitle,
  DetailText,
  DropdownMenu,
  Line,
  MenuBlock,
  MenuItem,
  PopoverContent,
  CarInfoContainer,
  TextStrong,
} from './loadsItem.style';
import { getPreviewBOL, getPreviewInvoice } from '../../../API/invoice';
import { deleteLoad, getLoads, removeDriver, updateLoadStatus } from '../../../API/loads';
import { ThreeDots } from '../../../assets/icons';
import ModalBOL from '../../../layout/Modal/ModalBOL/ModalBOL';
import AlertModal from '../../../layout/Modal/ModalLoads/AlertModal/AlertModal';
import ModalAssign from '../../../layout/Modal/ModalLoads/ModalAssign/ModalAssign';
import { InfoItem, TitleText, ValueText } from '../../../layout/Modal/ModalLoads/ModalViewLoads/modalViewLoads.style';
import ModalSendInvoice from '../../../layout/Modal/ModalSendInvoice/ModalSendInvoice';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { dollarSignDecorator } from '../../../shared/utils/common';
import { useAppDispatch } from '../../../store/hooks';
import { getLoadId } from '../../../store/slices/loadsSlice';
import Button from '../../Button/Button';

type menuOption = {
  callback: () => void;
  label: string;
  type?: 'warning' | 'default';
};

type menuLine = {
  content: React.ReactNode;
};

type menuItem = menuOption | menuLine;

type LoadItemProps = {
  loadItem: any;
};

const LoadsItem: React.FC<LoadItemProps> = ({ loadItem }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openInvoice, setOpenInvoice] = useState<boolean>(false);
  const [openBOL, setOpenBOL] = useState<boolean>(false);
  const [prePaidModalOpen, setPrePaidModalOpen] = useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [openAlertModal, setAlertModal] = useState<boolean>(false);
  const [openMarkNewAlertModal, setMarkNewAlertModal] = useState<boolean>(false);

  const navigation = useNavigate();

  const ref = useRef<any>();
  const popoverRef = useRef<any>();
  const refAssign = useRef<any>();
  const refInvoice = useRef<any>();
  const refBol = useRef<any>();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const showDropdownMenu = () => setShowMenu(!showMenu);

  const dispatch = useAppDispatch();

  const handleAction = async (action: string) => {
    await dispatch(getLoadId({ id: loadItem.id }));
    if (action === 'assign') {
      setOpen(true);
      setShowMenu(false);
      document.body.style.overflow = 'hidden';
    } else if (action === 'archive') {
      await dispatch(updateLoadStatus(['archived', loadItem.id]));
      await dispatch(
        getLoads({
          type: 'load_id',
          sortBy: 'none',
        })
      );
    } else {
      navigation(`/loads/${loadItem.id}`);
    }
  };

  const getInvoicePreview = async () => {
    const res = await dispatch(getPreviewInvoice({ load_id: loadItem.id }));

    const winHtml: string | any = res.payload;

    const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));

    window.open(winUrl, '_blank');
  };

  const getPreview = async (loadItem: any) => {
    const res = await dispatch(getPreviewBOL(loadItem.id));
    const winHtml: string | any = res.payload;
    const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
    window.open(winUrl, '_blank');
  };

  const openModalInvoice = () => {
    setOpenInvoice(true);
    setShowMenu(false);
    document.body.style.overflow = 'hidden';
  };

  const openModalBOL = () => {
    setOpenBOL(true);
    setShowMenu(false);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setOpen(false);
    document.body.style.overflow = 'unset';
  };

  const closeModalInvoice = () => {
    setOpenInvoice(false);
    document.body.style.overflow = 'unset';
  };

  const closeModalBOL = () => {
    setOpenBOL(false);
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  useOnClickOutside(refAssign, () => setOpen(false));
  useOnClickOutside(refInvoice, () => setOpenInvoice(false));
  useOnClickOutside(refBol, () => setOpenBOL(false));

  const menuItemContent = ({ callback, label, type = 'default' }) => {
    return (
      <MenuItem onClick={callback} type={type}>
        {label}
      </MenuItem>
    );
  };

  const handleDelete = async () => {
    await dispatch(deleteLoad(loadItem.id));
    await dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      })
    );
  };

  const menuItemConfig = (status: string) => {
    const config: menuItem[] = [];
    if (status !== 'In terminal') {
      config.push(
        {
          callback: async () => {
            await dispatch(updateLoadStatus(['in_terminal', loadItem.id]));
            await dispatch(
              getLoads({
                type: 'load_id',
                sortBy: 'none',
              })
            );
          },
          label: 'Add terminal',
        },
        { content: <Line /> }
      );
    }

    config.push({
      callback: () => handleAction('assign'),
      label: 'Reassign',
    });

    config.push({
      callback: async () => {
        await dispatch(removeDriver(loadItem.id));
        await dispatch(
          getLoads({
            type: 'load_id',
            sortBy: 'none',
          })
        );
      },
      label: 'Unassign',
    });

    if (status !== 'New') {
      config.push({
        callback: () => setMarkNewAlertModal(true),
        label: 'Mark as New',
      });
    }

    if (status !== 'Picked up') {
      config.push({
        callback: async () => {
          await dispatch(updateLoadStatus(['picked_up', loadItem.id]));
          await dispatch(
            getLoads({
              type: 'load_id',
              sortBy: 'none',
            })
          );
        },
        label: 'Mark as Picked Up',
      });
    }

    if (status !== 'Delivered') {
      config.push({
        callback: async () => {
          await dispatch(updateLoadStatus(['delivered', loadItem.id]));
          await dispatch(
            getLoads({
              type: 'load_id',
              sortBy: 'none',
            })
          );
        },
        label: 'Mark as Delivered',
      });
    }

    if (['Delivered', 'Picked up', 'Billed', 'Paid'].includes(loadItem.status)) {
      config.push({
        callback: openModalBOL,
        label: 'Send BOL',
      });
    }

    if (status !== 'Paid') {
      config.push({
        callback: async () => {
          await dispatch(updateLoadStatus(['paid', loadItem.id]));
          await dispatch(
            getLoads({
              type: 'load_id',
              sortBy: 'none',
            })
          );
        },
        label: 'Mark as Paid',
      });
    }

    if (loadItem.status !== 'Archived') {
      config.push(
        {
          callback: async () => {
            await dispatch(updateLoadStatus(['archived', loadItem.id]));
            await dispatch(
              getLoads({
                type: 'load_id',
                sortBy: 'none',
              })
            );
          },
          label: 'Mark as Archived',
        },
        {
          content: <Line />,
        }
      );
    }

    if (['Delivered', 'Picked up', 'Billed', 'Paid'].includes(loadItem.status)) {
      config.push({
        callback: () => getPreview(loadItem),
        label: 'View BOL',
      });
    }

    config.push(
      {
        callback: getInvoicePreview,
        label: 'View Invoice',
      },
      { content: <Line /> }
    );

    if (status !== 'Deleted') {
      config.push({
        callback: () => {
          setAlertModal(true);
        },
        label: 'Delete',
        type: 'warning',
      });
    }

    return config;
  };

  const handlePrePaid = () => {
    setPrePaidModalOpen(true);
  };

  const handleMoveToPaid = async () => {
    await dispatch(updateLoadStatus(['paid', loadItem.id]));
    await dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      })
    );
  };

  const popoverContent = (
    <PopoverContent>
      <InfoItem>
        <TitleText>Price</TitleText>
        <ValueText>{dollarSignDecorator(loadItem?.payment?.price)}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Method</TitleText>
        <ValueText>{loadItem?.payment?.method}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Broker Fee</TitleText>
        <ValueText>{dollarSignDecorator(loadItem.payment.broker_fee, '-')}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Driver Pay</TitleText>
        <ValueText>{dollarSignDecorator(loadItem?.payment.driver_pay, '-')}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Date</TitleText>
        <ValueText>{moment(loadItem?.payment?.date).format('MM/DD/YYYY')}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Paid Amount</TitleText>
        <ValueText>{dollarSignDecorator(loadItem?.payment?.paid_amount, '-')}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Reference Number</TitleText>
        <ValueText>{loadItem?.payment?.reference_number ? loadItem.payment.reference_number : '-'}</ValueText>
      </InfoItem>
      <InfoItem>
        <TitleText>Invoice ID</TitleText>
        <ValueText>{loadItem?.payment?.invoice_id ? loadItem.payment.invoice_id : '-'}</ValueText>
      </InfoItem>
    </PopoverContent>
  );

  return (
    <Wrapper>
      <Top>
        <LoadID elipsis onClick={() => handleAction('modal')}>
          Load ID:<LoadName elipsis>{loadItem.load_id}</LoadName>
        </LoadID>
        <Actions>
          {loadItem?.status === 'Billed' && (
            <Button text="Mark as Paid" margin="0 16px" pad="10px" handleButton={handlePrePaid} />
          )}
          {loadItem?.status === 'Delivered' && (
            <Button text="Send invoice" margin="0 16px" pad="10px" handleButton={openModalInvoice} />
          )}
          {'Paid' === loadItem.status && <Action onClick={() => handleAction('archive')}>{'Archive'}</Action>}
          <Action onClick={() => handleAction('assign')}>{loadItem.driver ? 'Reasign' : 'Assign'}</Action>
          <Action onClick={() => handleAction('modal')}>Edit</Action>
          <More onClick={showDropdownMenu}>
            <ThreeDots />
          </More>
        </Actions>
      </Top>
      <DriverInfo>
        <Top>
          <LoadsFlexColumn>
            <DriverExtra>
              <Status
                style={{
                  color: loadItem.status === 'New' ? '#0e71eb' : loadItem.status === 'Archived' ? '#9292A4' : '#0baa1b',
                  backgroundColor:
                    loadItem.status === 'New'
                      ? '#b0dcff'
                      : loadItem.status === 'Archived'
                        ? '#E9EAF8'
                        : 'rgba(11, 170, 27, 0.15)',
                }}
              >
                {loadItem.status}
              </Status>
              <LoadsDays>
                <TextStrong>{dollarSignDecorator(loadItem?.payment?.price)}</TextStrong> ({loadItem?.payment?.method})
              </LoadsDays>
            </DriverExtra>
            <LoadsPayMent
              ref={popoverRef}
              onMouseEnter={() => setPopoverOpen(true)}
              onMouseLeave={() => setPopoverOpen(false)}
            >
              {loadItem?.payment?.paid_amount ? (
                <>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={popoverOpen}
                    anchorEl={popoverRef.current}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={() => setPopoverOpen(false)}
                    disableRestoreFocus
                  >
                    {popoverContent}
                  </Popover>
                  Payment info
                </>
              ) : (
                'No Payment received.'
              )}
            </LoadsPayMent>
          </LoadsFlexColumn>
          <LoadsFlexColumnRight>
            {loadItem?.driver ? (
              <LoadsAssigned>
                Assigned to <LoadName>{loadItem?.driver?.first_name + ' ' + loadItem?.driver?.last_name}</LoadName>
              </LoadsAssigned>
            ) : (
              <LoadsAssigned>Not assigned</LoadsAssigned>
            )}
            <LoadsPhone>{loadItem?.driver?.phone}</LoadsPhone>
          </LoadsFlexColumnRight>
        </Top>

        <CarInfoContainer>
          {loadItem?.vehicles.map((vehicle, idx) => (
            <CarInfo key={`${idx}_${vehicle.id}`}>
              <Car>
                {vehicle?.year + ' ' + (vehicle?.make ?? 'unspecified') + ' ' + (vehicle?.model ?? 'unspecified')}
              </Car>
              <Type>
                Type: <CarName>{vehicle?.type}</CarName>
              </Type>
              <Vin>
                VIN #: <CarName>{vehicle?.vin}</CarName>
              </Vin>
            </CarInfo>
          ))}
        </CarInfoContainer>
        <Details>
          <DetailBlock>
            <DetailTitle>ORIGIN</DetailTitle>
            <DetailText elipsis>
              {loadItem?.points[0].business_name}
              <br />
              {loadItem?.points[0].street_address}
              <br />
              {loadItem?.points[0].state + ', ' + loadItem?.points[0].zip}
              <br />
              {loadItem?.points[0]?.date}
              <br />
              Phone: {loadItem?.points[0].phone ? loadItem?.points[0].phone : '-'}
            </DetailText>
          </DetailBlock>
          <DetailBlock>
            <DetailTitle>DESTINATION</DetailTitle>
            <DetailText elipsis>
              {loadItem?.points[1].business_name}
              <br />
              {loadItem?.points[1].street_address}
              <br />
              {loadItem?.points[1].state + ', ' + loadItem?.points[1].zip}
              <br />
              Phone: {loadItem?.points[1].phone ? loadItem?.points[1].phone : '-'}
            </DetailText>
          </DetailBlock>
          <DetailBlock>
            <DetailTitle>SHIPPER/CUSTOMER</DetailTitle>
            <DetailText elipsis>
              {loadItem?.customer.business_name}
              <br />
              Phone: {loadItem?.customer.phone ? loadItem?.customer.phone : '-'}
            </DetailText>
          </DetailBlock>
        </Details>
      </DriverInfo>

      {showMenu && (
        <DropdownMenu ref={ref}>
          <MenuBlock>
            {menuItemConfig(loadItem.status).map((itemElement) => {
              if ('content' in itemElement) return itemElement.content;

              return menuItemContent({
                callback: itemElement.callback,
                label: itemElement.label,
                ...(itemElement?.type && { type: itemElement.type }),
              });
            })}
          </MenuBlock>
        </DropdownMenu>
      )}

      {openAlertModal && (
        <AlertModal
          title='Move the load to "Deleted"?'
          submitButtonLabel="Remove"
          cancelButtonLabel="Cancel"
          onSuccess={() => {
            handleDelete();
            setAlertModal(false);
          }}
          onClose={() => {
            setAlertModal(false);
          }}
        >
          {`The load '${loadItem.load_id}' will be moved to "Deleted". You'll be able to restore it any time.`}
        </AlertModal>
      )}

      {openMarkNewAlertModal && (
        <AlertModal
          title='Move the load to "New"?'
          submitButtonLabel="Mark as new"
          cancelButtonLabel="Cancel"
          onSuccess={async () => {
            setMarkNewAlertModal(false);
            await dispatch(updateLoadStatus(['new', loadItem.id]));
            await dispatch(
              getLoads({
                type: 'load_id',
                sortBy: 'none',
              })
            );
          }}
          onClose={() => {
            setMarkNewAlertModal(false);
          }}
        >
          {`The load '${loadItem.load_id}' will be moved to "Mark as new".`}
        </AlertModal>
      )}

      {open && <ModalAssign refAssign={refAssign} itemPage={false} close={closeModal} />}
      {openInvoice && <ModalSendInvoice refInvoice={refInvoice} load={loadItem} close={closeModalInvoice} />}
      {openBOL && <ModalBOL refBol={refBol} load={loadItem} close={closeModalBOL} />}
      {prePaidModalOpen && (
        <MarkAsPaidModal
          onSuccess={handleMoveToPaid}
          open={prePaidModalOpen}
          load={loadItem}
          onClose={() => setPrePaidModalOpen(false)}
        />
      )}
    </Wrapper>
  );
};

export default LoadsItem;
